const faqs = [
  {
    question: "Requisitos para viajar a España",
    answer: (
      <div>
        <p>-  Pasaporte válido: Lo primero que debes asegurarte es que tu pasaporte esté vigente durante toda tu estancia en España. Además, es recomendable que verifiques que la fecha de vencimiento sea posterior a tu fecha de regreso a México.</p><br/>
        <p>- Visa o exención de visa: Los ciudadanos mexicanos no necesitan obtener una visa para estancias turísticas o de corta duración en España, siempre y cuando la visita no exceda los 90 días dentro de un período de 180 días. Esto se debe a un acuerdo de exención de visa entre ambos países.</p><br/>
        <p>- Documentación de respaldo: Es aconsejable llevar contigo documentos que respalden el propósito de tu viaje, como reservas de hotel, itinerarios, billetes de avión de ida y vuelta, seguro de viaje y suficiente dinero para cubrir tus gastos durante tu estadía.</p><br/>
        <p>- Seguro médico de viaje: Aunque no es un requisito obligatorio, se recomienda contar con un seguro médico de viaje que cubra gastos médicos y hospitalarios en caso de emergencias. La Tarjeta Sanitaria Europea (TSE) no es válida en España, por lo que un seguro privado te brindará la protección necesaria durante tu estancia.</p><br/>
        <p>- Vacunas y salud: No se exigen vacunas específicas para ingresar a España desde México. Sin embargo, es prudente mantener tus vacunas al día y, si tienes alguna condición de salud particular, es aconsejable llevar contigo un informe médico en inglés o español.</p><br/>
        <p>- Declaración de aduana: Al llegar a España, deberás completar una declaración de aduana, donde deberás detallar los bienes y el dinero en efectivo que llevas contigo. Esta declaración es estándar para todos los viajeros y puede ser revisada por las autoridades aduaneras.</p><br/>
       
    
      </div>
    ),
  },

 
];

export default faqs;
